$('.client-slider').slick({
    centerMode: true,
    slidesToShow: 1,
    variableWidth: true,
    dots: false,
    infinite: true,
    autoplay: true,
    //autoplay: false,
    arrows: false,
    centerPadding: '15px',
    initialSlide: 1
});