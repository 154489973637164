$('.gallery-grid').packery({
  // options
  itemSelector: '.grid-item',
  gutter: 0
});

// Lightcase
$('a[data-rel^=lightcase]').lightcase({
	maxWidth : 1400,
	maxHeight : 800,
	swipe: true
});
